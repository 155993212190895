body {
  margin: 0;
  background-color: yourBackgroundColorHere; /* replace with your background color */
}

.homepage {
  color: #e6e6e6;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; /* changed from row to column */
  justify-content: center;
  align-items: center;
}

.nameContainer h1, .welcomeContainer, .aboutMeButton {
  text-align: center;
  margin: 0;
  opacity: 0;
  animation: appear 3s forwards;
}

.nameContainer h1 {
  font-size: 3em;
  font-weight: bold;
}

.aboutButton {
  text-align: center;
  margin: 0;
  opacity: 0;
  animation: appear 3s forwards;
  animation-delay: 2s;
  margin-top: 10px;
  padding: 10px 20px;
}

.welcomeContainer p {
  text-align: center;
  margin: 0;
  opacity: 0;
  animation: appear 3s forwards;
  animation-delay: 1s;
}

@keyframes appear {
  to {
    opacity: 1;
  }
}