.contact {
  margin: auto; /* Center the container */
  padding: 20px;
  color: #000000;
  min-height: 100vh;
  max-width: 800px;
  text-align: center;
  border-radius: 10px;
}

.contact h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #e6e6e6;
}

.contactDetails {
  color: #e6e6e6;
  margin-top: 20px;
}

.contactDetails p {
  color: #e6e6e6;
  margin: 10px 0;
}

.contactDetails a {
  color: #0885d8;
  text-decoration: none;
}

.contactDetails a:hover {
  text-decoration: underline;
}
