.aboutMe {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px; /* Rounded corners */
  width: 100%; /* Full width */
  max-width: 800px; /* Max width of 800px */
  margin: auto; /* Center the container */
}

.aboutMe h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #e6e6e6;
}

.aboutMe p {
  color: #e6e6e6;
  font-size: 1.2em;
  line-height: 1.6;
  text-align: center;
}

.projectsButton {
  text-align: center;
  margin: 0;
  opacity: 0;
  animation: appear 2s forwards;
  animation-delay: 3s;
  margin-top: 10px;
  padding: 10px 20px;
}

@keyframes appear {
  to {
    opacity: 1;
  }
}

/* Change font size for mobile */
@media (max-width: 480px) {
  .text {
    font-size: 10px;
  }
}
