.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #272727;
  padding: 10px 20px; /* Added some padding on the sides */
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* Ensure it spans the entire width */
  width: auto; /* Change from 100% to auto */
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box; /* Ensure padding doesn't expand its size */
}

.navbar-logo {
  color: #ffffff;
  font-size: 24px;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 15px;
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.navbar-link:hover {
  text-decoration: underline;
}

.menu-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 1000; /* Ensure it appears above other elements */
}

@media (max-width: 768px) {
  .navbar-logo {
    font-size: 20px;
  }

  .navbar-links {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
  }

  .navbar-link {
    font-size: 16px;
  }
}