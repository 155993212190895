#star-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
  }
  
  .star {
    position: absolute;
    background-color: rgb(242, 220, 188);
    border-radius: 156%;
  }
  